@import "../common/commonStyles.scss";

.root {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  border-left: 1px solid var(--neutralLight);
  .paneHeader {
    padding: 30px 20px;
    font-weight: 600;
  }
  .chatPane {
    position: relative;
    flex-grow: 1;
  }
  .commandBar {
    border-bottom: 1px solid var(--neutralQuaternary);
    box-shadow: 0 0 6px var(--neutralQuaternary);
    background-color: var(--neutralLight);
    :global(.ms-CommandBar) {
      background-color: var(--neutralLight);
      padding: 0px;
      :global(.ms-Button) {
        background-color: var(--neutralLight);
      }
      :global(.ms-Button:hover) {
        background-color: var(--neutralLighter);
      }
    }
  }
  .largeHeaderText {
    font-size: 24px;
  }
}
.immersive {
  max-width: 1200px;
  padding: 6px 14px;
}
:global(.ms-ContextualMenu-linkContent) {
  font-size: 12px;
  max-width: 40vw;
}
