@import "../../commonStyles.scss";

.root {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding: 0px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .messages {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .userInput {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 80px;
    width: 100%;
    background-color: var(--themeLighter);
    textarea {
      height: 50px;
      padding: 4px;
      font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
        "Helvetica Neue", sans-serif;
      font-size: 12px;
      flex-grow: 1;
      margin: 10px;
      margin-right: 0px;
      border: 1px solid var(--themeLight);
      resize: none;
    }
    .buttonsPane {
      display: flex;
      flex-direction: column;
      justify-items: space-around;
      padding: 8px 0px;
      .resetButton,
      .sendButton {
        margin: 2px;
      }
    }
  }
}
.suggestionRow,
.messageRow {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0px;
  margin-bottom: 10px;
  .systemIcon,
  .userIcon {
    margin: 10px 4px;
    font-size: 16px;
    font-weight: 200;
  }
  .userImage {
    margin: 10px 4px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }
  .suggestion,
  .message {
    position: initial;
    display: inline-block;
    text-align: left;
    border-radius: 15px;
    margin: 0px;
    padding: 2px 6px;
    background-color: var(--white);
    li {
      margin-bottom: 8px;
    }
    p {
      margin: 6px;
    }
  }
  .messageLeftAlign {
    border-top-left-radius: 0px;
    margin-right: 20px;
  }
  .messageRightAlign {
    border-top-right-radius: 0px;
    margin-left: 20px;
    background-color: var(--themeLighter);
  }
  .suggestion {
    border-top-right-radius: 15px;
    margin-right: 28px;
    color: var(--themePrimary);
    background-color: var(--themeLightest);
    border-color: var(--themeLight);
    padding: 6px 10px;
    font-weight: 600;
  }
  .suggestion:hover {
    background-color: var(--themeLighter);
    border-color: var(--themeTertiary);
    cursor: pointer;
  }
}
.messageRowLeftAlign {
  justify-content: flex-start;
}
.suggestionRow,
.messageRowRightAlign {
  justify-content: flex-end;
}
.suggestionRow {
  margin: 2px;
}
.messageActions {
  position: absolute;
  bottom: -40px;
  left: 26px;
  padding: 10px;
  background-color: transparent;
  z-index: 10;
  .actionIcon {
    font-size: 16px;
    padding: 4px;
    margin-right: 4px;
  }
  .actionIcon:hover {
    cursor: pointer;
    border-radius: 4px;
    color: var(--themePrimary);
    background-color: var(--neutralQuaternaryAlt);
  }
  .actionIcon:active {
    background-color: var(--neutralQuaternary);
  }
}
.disclaimerText {
  padding: 30px;
  text-align: center;
  font-size: 11px;
  font-weight: 100;
}
